.menu {
  width: 0;

  height: 105vh;

  background-color: white;

  position: absolute;

  z-index: 99;

  right: 0;

  top: 0;

  -webkit-transition: all 0.3s;

  transition: all 0.3s;

  overflow: hidden;

  -webkit-box-shadow: 0px 5px 5px 0px rgba(82, 79, 76, 0.26);

  box-shadow: 0px 5px 5px 0px rgba(82, 79, 76, 0.26);
}

.menu-container {
  padding: 2rem 0;

  display: none;

  -webkit-transition: opacity 0.1s;

  transition: opacity 0.1s;
}

.links {
  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;

  -webkit-box-orient: vertical;

  -webkit-box-direction: normal;

  -ms-flex-direction: column;

  flex-direction: column;

  margin: 80px 0 20px 0;
}

.links a {
  text-decoration: none;

  font-family: hero-new, sans-serif;

  font-weight: 700;

  font-size: 2rem;

  color: #e7522a;

  padding: 0 15px;

  margin: 15px 0;
}

@media screen and (max-width: 1024px) {
  .mobile-hidden {
    display: none;
  }

  .menu-btn {
    display: block;

    font-size: 1.9rem;

    padding: 1.5rem 1.5rem;

    color: white;
  }

  .menu-container {
    display: flex;

    justify-content: space-around;
  }

  .fa-times-circle {
    font-size: 2.4rem;
  }

  .logo {
    width: 7em;
  }

  .hero h1 {
    font-size: 100px;

    line-height: 90px;
  }

  .two-videos {
    display: flex;

    flex-direction: column;
  }

  .two-videos iframe {
    width: auto;
  }
}

@media screen and (max-width: 1024px) {
  .hero h1 {
    font-size: 60px;
  }
}


@media screen and (max-width: 615px) { 
.two-img-div {
  flex-direction: column;
}



}

@media (max-width: 630px) {
  .some-div-footer {
    flex-direction: column;
    align-items: center;
  }
  .some-container {
    padding: 30px;
  }
}

@media screen and (max-width: 415px) {
  /*SUBPAGES AT BOTTOM*/

  .mobile-hidden {
    display: none;
  }

  h2 {
    font-size: 40px;
  }

  .logo {
    width: 6rem;
  }

  main {
    max-width: 375px;
  }

  .hero {
    padding: 5rem 1rem 0 1rem;

    height: 55vh;
  }

  .hero h1 {
    font-size: 54px;

    line-height: 70px;
  }

  .modal,
  .rex-modal {
    max-width: 375px;
  }

  .modal-content {
    width: auto;

    margin: 50% auto;
  }

  .modal-content iframe {
    width: 100%;

    max-height: 220px;
  }

  .showreel {
    position: absolute;

    right: 10px;
  }

  #showreel-btn {
    padding: 0;
  }

  .section-1 {
    flex-direction: column;

    height: unset !important;

    padding: 1rem;
  }

  .center-mobile {
    align-items: center;
  }

  .some-links {
    flex-direction: row;

    justify-content: space-around;
  }

  .some-links a {
    padding: 1rem;
  }

  .img-section {
    max-width: 375px;

    height: auto;
  }

  /* .img-section img {

    height: fit-content;

    object-fit: cover;

  } */

  .branding-heading-mobile {
    text-align: center;

    right: 20px;

    left: 20px;
  }

  .text-box {
    width: 100%;

    display: flex;

    flex-direction: column;

    margin: 1rem 0;

    align-self: center;
  }

  .text-box a {
    margin: 2.5rem 0;
  }

  .double-div {
    flex-direction: column;
  }

  .cuckoo {
    flex-direction: column;

    padding: 5rem 2rem 1rem 2rem;

    height: unset;
  }

  .single-div {
    padding: 0;

    margin: 0;
  }

  .single-div img {
    width: 375px;
  }

  .contact {
    padding: 1rem;
  }

  /*FOOTER*/

  .img-div {
    padding: 1rem;

    grid-template-columns: auto auto;
  }

  .img-div img {
    max-width: 9rem;
  }

  .some-container {
    padding: 2rem;
  }

  .some-div-footer {
    flex-direction: column;

    align-items: center;
  }

  /*SUBPAGES*/

  .case-grid {
    grid-template-columns: 1fr;

    grid-auto-rows: unset;
  }

  .case-text h2,
  .case-text h3 {
    padding: 1rem 1rem 0 1rem;
  }

  .case-text h3 {
    font-size: 35px;
  }

  .img-section {
    max-height: none;
  }

  .two-img-div {
    display: block;
  }

  .video-div {
    margin: 2rem 1rem;
  }

  .contact-hero {
    flex-direction: column;
  }

  .contact-info {
    width: auto;
  }

  .contact-info h1 {
    font-size: 35px;
  }

  .contact-info h2 {
    font-size: 30px;
  }

  .contact-info p,
  .contact-info a {
    font-size: 18px;
  }

  .map {
    width: 100%;

    height: 100%;
  }

  .sub-main {
    padding: 0.5rem;
  }

  .sub-main h1 {
    text-align: center;

    font-size: 50px;

    line-height: 43px;
  }

  .staff-grid {
    grid-template-columns: 1fr;

    margin: 1.5rem;
  }

  .img-left img,
  .img-right img {
    width: 100%;
  }

  .img-full,
  .img-left,
  .img-right {
    margin-bottom: 1rem;
  }

  .next-prev-case h1 {
    font-size: 25px;
  }
}

@media screen and (max-width: 376px) {
  /*SUBPAGES AT BOTTOM*/

  .mobile-hidden {
    display: none;
  }

  h2 {
    font-size: 40px;
  }

  .logo {
    width: 6rem;
  }

  main {
    max-width: 375px;
  }

  .hero {
    padding: 5rem 1rem 0 1rem;

    height: 55vh;
  }

  .hero h1 {
    font-size: 54px;

    line-height: 70px;
  }

  .modal,
  .rex-modal {
    max-width: 375px;
  }

  .modal-content {
    width: auto;

    margin: 50% auto;
  }

  .modal-content iframe {
    width: 100%;

    max-height: 220px;
  }

  .showreel {
    position: absolute;

    right: 10px;
  }

  #showreel-btn {
    padding: 0;
  }

  .section-1 {
    flex-direction: column;

    height: unset !important;

    padding: 1rem;
  }

  .center-mobile {
    align-items: center;
  }

  .some-links {
    flex-direction: row;

    justify-content: space-around;
  }

  .some-links a {
    padding: 1rem;
  }

  .img-section {
    max-width: 375px;

    height: auto;
  }

  /* .img-section img {

    height: fit-content;

    object-fit: cover;

  } */

  .branding-heading-mobile {
    text-align: center;

    right: 20px;

    left: 20px;
  }

  .text-box {
    width: 100%;

    display: flex;

    flex-direction: column;

    margin: 1rem 0;

    align-self: center;
  }

  .text-box a {
    margin: 2.5rem 0;
  }

  .double-div {
    flex-direction: column;
  }

  .cuckoo {
    flex-direction: column;

    padding: 5rem 2rem 1rem 2rem;

    height: unset;
  }

  .single-div {
    padding: 0;

    margin: 0;
  }

  .single-div img {
    width: 375px;
  }

  .contact {
    padding: 1rem;
  }

  /*FOOTER*/

  .img-div {
    padding: 1rem;

    grid-template-columns: auto auto;
  }

  .img-div img {
    max-width: 9rem;
  }

  .some-container {
    padding: 2rem;
  }

  .some-div-footer {
    flex-direction: column;

    align-items: center;
  }

  /*SUBPAGES*/

  .case-grid {
    grid-template-columns: 1fr;

    grid-auto-rows: unset;
  }

  .case-text h2,
  .case-text h3 {
    padding: 1rem 1rem 0 1rem;
  }

  .case-text h3 {
    font-size: 35px;
  }

  .img-section {
    max-height: none;
  }

  .two-img-div {
    display: block;
  }

  .video-div {
    margin: 2rem 1rem;
  }

  .contact-hero {
    flex-direction: column;
  }

  .contact-info {
    width: auto;
  }

  .contact-info h1 {
    font-size: 35px;
  }

  .contact-info h2 {
    font-size: 30px;
  }

  .contact-info p,
  .contact-info a {
    font-size: 18px;
  }

  .map {
    width: 100%;

    height: 100%;
  }

  .sub-main {
    padding: 0.5rem;
  }

  .sub-main h1 {
    text-align: center;

    font-size: 50px;

    line-height: 43px;
  }

  .staff-grid {
    grid-template-columns: 1fr;

    margin: 1.5rem;
  }

  .img-left img,
  .img-right img {
    width: 100%;
  }

  .img-full,
  .img-left,
  .img-right {
    margin-bottom: 1rem;
  }

  .next-prev-case h1 {
    font-size: 25px;
  }
}
